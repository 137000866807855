<!-- 我的 -->
<template>
  <div class="Personal">
    <div class="loginfo" @click="nextPage">
      <img class="portriat" src="@/assets/images/my_and_service/default_portriat.png" />
      <div class="main van-ellipsis">
        <p class="text van-ellipsis">
          {{
            loginInfo.userId
              ? `你好${loginInfo.userName ? "，" + loginInfo.userName : " "}`
              : "未登录"
          }}
        </p>
        <p class="phone" v-show="loginInfo.userPhone">
          {{ loginInfo.userPhone }}
        </p>
      </div>
      <img class="right" src="@/assets/images/right_arrow.png" />
    </div>
    <div class="mainInfo">
      <template v-for="item in list">
        <div class="item" v-show="
            (loginInfo.userId && item.name === '我的订单') ||
              item.name !== '我的订单'
          " :key="item.name" @click="clickEvent(item.name)">
          <img :src="item.icon" alt="" />
          <p>{{ item.name }}</p>
        </div>
      </template>
    </div>
    <ImgSwiper v-if="bottomBanner.length" :bannerList="bottomBanner" />
    <div v-show="loginInfo.userId" class="logout" @click="logOut">
      退出登录
    </div>
    <Footer :active="3" />
    <van-popup class="codePopup" v-model="showCode">
      <div class="code">
        <img src="@/assets/images/my_and_service/code.png" alt="" />
        <p class="name">易鑫车主服务</p>
        <p class="letter">公众号：yixinchezhufuwu</p>
      </div>
      <img class="close" @click="showCode = false" src="@/assets/images/close.png" />
    </van-popup>
  </div>
</template>

<script>
import { userLogout } from "@/api/api_personal";
import { getBannerList } from "@/api/api_home";
import { mapState } from "vuex";
import ImgSwiper from "@/components/Swiper.vue";
export default {
  name: "Personal",
  data () {
    return {
      bottomBanner: [],
      showCode: false,
      list: [
        {
          name: "我的订单",
          icon: require("@/assets/images/my_and_service/my_order.png")
        },
        {
          name: "客服电话",
          icon: require("@/assets/images/my_and_service/my_phone.png")
        },
        {
          name: "车主公众号",
          icon: require("@/assets/images/my_and_service/my_weChat.png")
        },
        {
          name: "帮助中心",
          icon: require("@/assets/images/my_and_service/my_help.png")
        },
        {
          name: "设置",
          icon: require("@/assets/images/my_and_service/setting.png")
        }
      ]
    };
  },
  computed: {
    ...mapState(["loginInfo", "position"])
  },
  created () {
    this.getImgList();
  },
  methods: {
    // 获取广告
    getImgList () {
      getBannerList({
        cityCode: this.position.cityId,
        adPage: 7
      }).then(res => {
        if (res.code === 0) {
          const info = res.data.info;
          this.bottomBanner = info.bottomBanner;
        }
      });
    },
    // 退出登录
    logOut () {
      userLogout({ userId: this.loginInfo.userId, deviceId: "h5" }).then(
        res => {
          if (res.code === 0) {
            this.$store.commit("setLoginInfo", {});
          }
        }
      );
    },
    nextPage () {
      // 根据 userId 判断登录状态
      if (this.loginInfo.userId) {
        this.$router.push({ name: "MyInfo" });
      } else {
        this.$router.push({ name: "Login" });
      }
    },
    clickEvent (name) {
      if (name === "我的订单") {
        this.$router.push({ name: "MyOrder" });
      } else if (name === "客服电话") {
        window.location.href = "tel:4000598598";
      } else if (name === "车主公众号") {
        this.showCode = true;
      } else if (name === "帮助中心") {
        this.$router.push({ name: "HelpInfo" });
      } else if (name === "设置") {
        this.$router.push({ name: "Setting" });
      }
    }
  },
  components: { ImgSwiper }
};
</script>
<style lang="scss" scoped>
.Personal {
  .loginfo {
    display: flex;
    align-items: center;
    padding: 0.28rem 0.18rem 0.27rem;
    .portriat {
      width: 0.5rem;
      height: 0.5rem;
      margin-right: 0.16rem;
    }
    .main {
      flex: 1;
      .phone {
        font-size: 0.14rem;
        line-height: 0.2rem;
        color: #6e727a;
        letter-spacing: 0.01rem;
        margin-top: 0.02rem;
      }
      .text {
        font-size: 0.24rem;
        font-weight: bold;
        line-height: 0.33rem;
        color: $mainBlack;
      }
    }
    .right {
      width: 0.07rem;
    }
  }
  .mainInfo {
    background: #ffffff;
    border-radius: 0.08rem;
    margin: 0 0.18rem 0.16rem;
    padding: 0 0.2rem;
    .item {
      display: flex;
      border-bottom: 1px solid #f1f2f6;
      font-size: 0.15rem;
      color: $mainBlack;
      display: flex;
      align-items: center;
      height: 0.58rem;
      img {
        width: 0.2rem;
        height: 0.2rem;
        margin-right: 0.16rem;
      }
    }
  }
  .logout {
    background: #ffffff;
    border-radius: 0.08rem;
    height: 0.58rem;
    font-size: 0.15rem;
    font-weight: bold;
    color: $mainRed;
    margin: 0.2rem 0.18rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
<style lang="scss">
.Personal .codePopup {
  background: transparent;
  .code {
    background: #ffffff;
    border-radius: 0.12rem;
    padding: 0.4rem 0.62rem;
    text-align: center;
    margin-bottom: 0.3rem;
    img {
      width: 1.7rem;
      height: 1.7rem;
    }
    .name {
      font-size: 0.15rem;
      color: $mainBlack;
      line-height: 0.21rem;
      margin: 0.2rem 0 0.08rem;
    }
    .letter {
      font-size: 0.12rem;
      color: #8d8d91;
      line-height: 0.17rem;
    }
  }
  .close {
    margin: 0 auto;
    display: block;
    width: 0.36rem;
    height: 0.36rem;
  }
}
</style>
